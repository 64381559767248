var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trips-table" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.trips && _vm.deviceList,
              expression: "trips && deviceList"
            }
          ],
          staticClass: "trips-list-container"
        },
        [
          _c("b-table", {
            ref: "tripsTable",
            staticClass: "trips-list-table",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.trips,
              fields: _vm.fields,
              selectable: _vm.selectable,
              responsive: "sm",
              "tbody-tr-class": "table-body-centered"
            },
            scopedSlots: _vm._u([
              {
                key: "cell(view_trip)",
                fn: function(row) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          { name: "b-tooltip", rawName: "v-b-tooltip" }
                        ],
                        attrs: {
                          variant: "success",
                          title: "Open Trip in New Tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showTrip(row.item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-map-marker save-icons" })]
                    )
                  ]
                }
              },
              {
                key: "cell(device_imei)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex-column align-items-center" },
                      [
                        _vm.deviceList
                          ? _c("DeviceAvatar", {
                              attrs: {
                                device: _vm.getDevice(row.item.device_imei)
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(start_time)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex-column align-items-center" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.dt.timestampToLocalDateTime(
                                row.item.start_time
                              )
                            )
                          )
                        ]),
                        _c("div", { staticClass: "address-text" }, [
                          _vm._v(_vm._s(_vm.getAddress(row.item, "start")))
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "cell(end_time)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex-column align-items-center" },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.dt.timestampToLocalDateTime(row.item.end_time)
                            )
                          )
                        ]),
                        _c("div", [
                          _vm._v(_vm._s(_vm.getAddress(row.item, "end")))
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "cell(state)",
                fn: function(row) {
                  return [
                    _vm.isVehicleTrip(row.item)
                      ? _c(
                          "div",
                          { staticClass: "flex-column align-items-center" },
                          [
                            _vm.isVehicleTrip(row.item)
                              ? _c("img", {
                                  staticClass: "trip-icon",
                                  attrs: { src: _vm.getTripIcon(row.item) }
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(row.item.state) + " ")
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "flex-column align-items-center" },
                          [
                            _c("i", {
                              staticClass: "trip-icon trip-icon-border",
                              class: _vm.$config.icons.general.locations
                            }),
                            _vm._v(" LOCATION ")
                          ]
                        )
                  ]
                }
              },
              {
                key: "cell(modified)",
                fn: function(row) {
                  return [
                    row.item.modified
                      ? _c("div", { staticClass: "slim-controls" }, [
                          _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.showTripAuditLog(row.item)
                                }
                              }
                            },
                            [_vm._v("Yes")]
                          )
                        ])
                      : _c("span", [_vm._v("No")])
                  ]
                }
              },
              {
                key: "cell(work_use)",
                fn: function(row) {
                  return [
                    _c("b-checkbox", {
                      on: {
                        change: function($event) {
                          return _vm.rowEdited(row.item.id, row.item.id)
                        }
                      },
                      model: {
                        value: row.item.work_use,
                        callback: function($$v) {
                          _vm.$set(row.item, "work_use", $$v)
                        },
                        expression: "row.item.work_use"
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(tags)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "tags-container" },
                      [
                        _c("TripTags", {
                          attrs: { editable: "" },
                          on: {
                            input: function($event) {
                              return _vm.rowEdited(row.item.id)
                            }
                          },
                          model: {
                            value: row.item.tags,
                            callback: function($$v) {
                              _vm.$set(row.item, "tags", $$v)
                            },
                            expression: "row.item.tags"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(forms)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "tags-container" },
                      [
                        _c("FormListInline", {
                          attrs: { editable: "" },
                          on: { input: _vm.tripFormsUpdated },
                          model: {
                            value: row.item,
                            callback: function($$v) {
                              _vm.$set(row, "item", $$v)
                            },
                            expression: "row.item"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(annotation)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "align-content-center" },
                      [
                        _c("b-form-input", {
                          staticClass: "custom-control custom-checkbox",
                          attrs: { debounce: "1000" },
                          on: {
                            input: function($event) {
                              return _vm.rowEdited(row.item.id)
                            }
                          },
                          model: {
                            value: row.item.annotation,
                            callback: function($$v) {
                              _vm.$set(row.item, "annotation", $$v)
                            },
                            expression: "row.item.annotation"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(save)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "fixed-width-save" },
                      [
                        _vm.editedRows.includes(row.item.id)
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "success" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateTrip(row.item)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-check save-icons"
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm.editedRows.includes(row.item.id)
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.resetRow(row.item.id)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-times save-icons"
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.trips === null || _vm.trips.length === 0
        ? _c("div", { staticClass: "trips-list-container" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-trip-auditlog",
            centered: "",
            size: "lg",
            title: "Trip Auditlog",
            "ok-only": "",
            "ok-title": "Close"
          }
        },
        [
          _vm.auditLogTrip
            ? _c("AuditLog", {
                attrs: { related_type: "trip", related_id: _vm.auditLogTrip.id }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "no-trips-container" }, [
      _c("h3", { staticClass: "no-trips-text" }, [
        _vm._v("No trips found for this period.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }