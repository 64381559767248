<template>
  <div class="logbook-exporter">
      <form class="export-form"
            v-on:submit.prevent="submitExport">
        <div class="flex-row align-items-start p-2">
          <div class="flex-column flex-grow-1">
            <div class="flex-column">
              <label>Device:</label>
                <b-form-select required
                               v-model="selectedDevice"
                               :options="deviceOptions"
                ></b-form-select>
            </div>
            <div class="flex-column mt-2">
              <label for="select-month">Date Range:</label>
              <DateRangeSelector horizontal select_picker @input="dateSelected" ref="datePicker"
                                 @date_mode_change="dateModeChange"
                                 :default_filter_mode="default_filter_mode"
                                 :default_month="default_month"
                                 :default_start_date="default_start_date"
                                 :default_end_date="default_end_date"
                                 :default_calendar_year="default_calendar_year"
                                 :default_financial_year="default_financial_year"
              ></DateRangeSelector>
            </div>

            <div class="flex-row mt-2">
              <div class="flex-column">
                <label class="green-label" for="select-month">
                  Trip Purpose:
                </label>
                <b-form-select
                  id="select-month"
                  v-model="tripPurposeFilter"
                  :options="tripPurposeFilterOptions"
                ></b-form-select>
              </div>
              <div class="flex-column ml-5">
                <label class="green-label" for="select-month">
                  Trip State:
                </label>
                <b-form-select
                  id="select-month"
                  v-model="tripStateFilter"
                  :options="tripStateFilterOptions"
                ></b-form-select>
              </div>
            </div>
            <div class="flex-row mt-2">
              <div class="flex-column">
                <label class="green-label" for="select-month">
                  Correct Odometer:
                </label>
                <b-checkbox
                  id="select-month"
                  :checked="correctOdometer===true"
                  @change="toggleOdometerCorrect"
                >Specify Odometer Reading</b-checkbox>
              </div>
              <div v-if="correctOdometer">
                <label class="green-label" for="select-month">
                  Final Odometer Reading:
                </label>
                <input
                  required
                  min="0"
                  type="number"
                  id="select-month"
                  v-model="finalOdometerReading"
                >
              </div>
            </div>
          </div>
          <div class="hint-box">
            <h5>Logbook Exports</h5>
            <p>Logbook exports are used to generate a CSV file containing all of the trips a device has taken during a selected period. The extract
              contains times, distances, durations and odometer readings and is designed to be used for vehicle KM reports required for tax purposes.</p>
            <p>
              If the final odometer reading in the report is incorrect (for example if you haven't updated your odometer in the system before, you
              can specify what the final odometer reading for the report should be.
            </p>

          </div>
        </div>
        <div class="modal-footer">
          <button type="submit">Export to CSV</button>
          <button v-if="modal_id" @click="$bvModal.hide(modal_id)">Cancel</button>
        </div>
      </form>
    <TaskProgress v-if="this.taskId" :task_id="taskId" @finish="taskComplete"></TaskProgress>
  </div>
</template>
<script>

import DateRangeSelector from '@/components/shared/DateRangeSelector.vue'
import * as DataProvider from '@/components/helpers/DataProvider'
import TaskProgress from '@/components/stats/TaskProgress.vue'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'
import {MetricFormatter as mf} from '@/components/helpers/MetricFormatter'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
export default {
  name: 'LogbookExporter',
  components: {TaskProgress, DateRangeSelector},
  props: {
    modal_id: String,
    // These date settings let the exporter sync it's Date Range Picker with the parent's.
    default_filter_mode: String,
    default_month: Object,
    default_start_date: String,
    default_end_date: String,
    default_calendar_year: Number,
    default_financial_year: Number,
    device_imei: String
  },
  emits: [
  ],
  data () {
    return {
      deviceList: null,
      selectedDevice: null,
      tripPurposeFilterOptions: [
        // One the server side this is a boolean filter. True means work use only
        {
          value: null,
          text: 'Any'
        },
        {
          value: true,
          text: 'Work Only'
        },
        {
          value: false,
          text: 'Personal Only'
        }
      ],
      tripPurposeFilter: null,
      tripStateFilterOptions: [
        // This is a filter base on the trip state enum. MOVING is 200, PARKED is 300
        {
          value: null,
          text: 'Any'
        },
        {
          value: 200,
          text: 'Moving Only'
        },
        {
          value: 300,
          text: 'Parked Only'
        }
      ],
      tripStateFilter: null,
      correctOdometer: false,
      finalOdometerReading: null,
      taskId: null,
      dateRange: null,
      logbookExport: null
    }
  },
  async mounted () {
    await this.getDeviceList()
    if (this.device_imei) {
      this.selectedDevice = this.device_imei
    }
  },
  methods: {
    /***
     * Fetch a list of devices for the user.
     * @return {Promise<void>}
     */
    async getDeviceList() {
      let response = await DataProvider.getDeviceList(['device_imei', 'device_name', 'odometer_reading', 'features'])
      this.deviceList = response.data
      console.log('resp: ', response)
    },
    /***
     * Handle the Custom Odometer reading checkbox changing state.
     *
     * Note: v-model doesn't work with checkboxes, so we use this event driven structure instead.
     * @param newVal
     */
    toggleOdometerCorrect (newVal) {
      this.correctOdometer = newVal
      if (newVal) {
        let device = Object.values(this.deviceList).find(device => device.device_imei === this.selectedDevice)
        if (device) {
          this.finalOdometerReading = device.odometer_reading
        }
      } else {
        this.finalOdometerReading = null
      }
    },
    /***
     * Handle Date Mode Change Event from the Date Picker
     * We use this to clear the existing date range, in case the picker goes into Month or Custom modes
     * @param newMode
     */
    dateModeChange(newMode) {
      this.dateRange = null
    },
    /***
     * Handle the Date Range Selection event from the data picker.
     * @param d
     */
    dateSelected (d) {
      console.log('DateSelected', d)
      this.dateRange = d
    },
    /***
     * Handle Submitting the export form (including a little bit of custom validation to trigger the date picker)
     */
    async submitExport() {
      if (!this.dateRange) {
        this.$refs.datePicker.setCustomValidity('Must Select a Date Range')
        return
      }
      let start = dt.toTimestamp(this.dateRange.start)
      let end = dt.toTimestamp(this.dateRange.end)
      let taskReq = await DataProvider.getLookbook(this.selectedDevice, start, end,
        this.tripStateFilter, this.tripPurposeFilter, this.finalOdometerReading)
      if (taskReq.success) {
        this.taskId = taskReq.data.task_created.id
      } else {
        ErrorHelper.displayDataErrorToast(taskReq)
      }
    },
    taskComplete (status, data) {
      if (status === TaskProgress.TASK_STATES.FAILED) {
        console.log('TASK FAILED: ', data)
      } else if (status === TaskProgress.TASK_STATES.COMPLETE) {
        this.logbookExport = data
        this.exportLogbook(data)
      }
    },
    exportLogbook (data) {
      // Papaparse doesn't seem to support this sort of layout, so we're generating the summary 'manually'
      let summary = `"# Protekt GPS Tracking Logbook Export"
      "# Device Name:", ${data.device.device_name},
      "# Device Code:", ${data.device.device_code}
      "# Period Start:", ${dt.toLocalDateTime(data.period_start)}
      "# Period End:", ${dt.toLocalDateTime(data.period_end)}
      "# Report Generated:", ${dt.toLocalDateTime()}
      "# Odometer Start:", ${mf.numberFormat(data.odometer_start, 2)}
      "# Odometer End:", ${mf.numberFormat(data.odometer_end, 2)}
      "# Total Trip Distance Shown:", ${mf.numberFormat(data.trips_travel_distance, 2)}
      "# Total Distance in Period:", ${mf.numberFormat(data.period_total_travel, 2)}
      "# Extra Report Parameters:", ${Object.keys(data.filters).map(filterKey => filterKey + '=' + data.filters[filterKey])}

      `
      /* Leave that extra blank line in there, it separates the summary from the data in the final export */

      // Do keep this updated in case we need it later
      // let headers = {
      //   device_code: 'Device Code',
      //   device_name: 'Device Name',
      //   start_time: 'Start Time [DD-MM-YYYY HH:mm]',
      //   start_odometer: 'Start Odometer [DD-MM-YYYY HH:mm]',
      //   start_latitude: 'Start Latitude',
      //   start_longitude: 'Start Longitude',
      //   start_address: 'Start Address',
      //   end_time: 'End Time',
      //   end_odometer: 'End Odometer',
      //   end_latitude: 'End Latitude',
      //   end_longitude: 'End Longitude',
      //   end_address: 'End Address',
      //   kms_travelled: 'Distance [KM]',
      //   time_travelled: 'Time [HH:MM:SS]',
      //   state: 'Trip State',
      //   duration: 'Duration [HH:MM:SS]',
      //   work_use: 'Work Use?',
      //   tags: 'Trip Tags',
      //   annotation: 'Trip Notes'
      // }

      // PapaParse Configuration
      let config = {
        quotes: false, // or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        newline: '\r\n',
        skipEmptyLines: false, // or 'greedy',
        columns: null // or array of strings
      }
      let tripsData = data.trips.map((trip) => {
        return {
          device_code: data.device.device_code,
          device_name: data.device.device_name,
          start_time: dt.timestampToLocalDateTime(trip.start_time),
          start_odometer: mf.numberFormat(trip.start_odometer, 2),
          start_latitude: trip.start_latitude.toFixed(5),
          start_longitude: trip.start_longitude.toFixed(5),
          start_address: this.getAddress(trip.start_location_address),
          end_time: dt.timestampToLocalDateTime(trip.end_time),
          end_odometer: mf.numberFormat(trip.end_odometer, 2),
          end_latitude: trip.end_latitude.toFixed(5),
          end_longitude: trip.end_longitude.toFixed(5),
          end_address: this.getAddress(trip.end_location_address),
          kms_travelled: mf.numberFormat(trip.kms_travelled, 2),
          time_travelled: dt.secondsToTime(trip.time_taken),
          state: trip.state,
          duration: dt.secondsToTime(trip.time_taken),
          work_use: trip.work_use,
          tags: Object.keys(trip.tags).map(key => trip.tags[key] ? key : ''),
          annotation: trip.annotation
        }
      })

      let tripsCSV = this.$papa.unparse(tripsData, config)
      let filename = 'logbook_' + data.device.device_code + '_' + dt.toLocalDateTime() + '.csv'
      this.$papa.download(summary + tripsCSV, filename, 'text/csv')
      if (this.modal_id) {
        this.$bvModal.hide(this.modal_id)
      }
    },
    getAddress (address) {
      /* Get the address from address.data.address_text.
      Any those things might not exist, in which case we return null */
      if (address && address.data && address.data.address_text) {
        return address.data.address_text
      } else {
        return null
      }
    }
  },
  computed: {
    /***
     * Creates a list of devices, formatted for use in a Select Box.
     * @return {{disabled: boolean, text: string, value: null}[]|*[]}
     */
    deviceOptions: function () {
      if (!this.deviceList) {
        return []
      } else {
        // Filter out devices which can't do trips (and so can't have a logbook)
        let tripDevices = Object.values(this.deviceList).filter(d => d.features.includes('device_trips'))
        return [
          {
            value: null,
            text: 'Select Device',
            disabled: true
          }
        ].concat(tripDevices.map((device) => {
          return {
            text: device.device_name,
            value: device.device_imei
          }
        }))
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.logbook-exporter {
  display: flex;
  flex-flow: column wrap;
}

.logbook-row {
  display: flex;
  flex-flow: row nowrap;
}

.logbook-row > div:not(:first-child) {
  margin-left: 2em
}

.export-form {
  display: flex;
  flex-flow: column wrap;
}

.green-label {
  margin-top: 0;
}

.custom-checkbox {
  padding-left: 1.8em;
  padding-top: 0.75em;
}

.custom-select {
  padding-right: 2em;
}

.text-input {
  color-scheme: dark;
  border-radius: 5px;
  background-color: rgba(0,0,0, 0.25);
}

.hint-box {
  max-width: 20em;
}

</style>
