var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logbook-exporter" },
    [
      _c(
        "form",
        {
          staticClass: "export-form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitExport.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "flex-row align-items-start p-2" }, [
            _c("div", { staticClass: "flex-column flex-grow-1" }, [
              _c(
                "div",
                { staticClass: "flex-column" },
                [
                  _c("label", [_vm._v("Device:")]),
                  _c("b-form-select", {
                    attrs: { required: "", options: _vm.deviceOptions },
                    model: {
                      value: _vm.selectedDevice,
                      callback: function($$v) {
                        _vm.selectedDevice = $$v
                      },
                      expression: "selectedDevice"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-column mt-2" },
                [
                  _c("label", { attrs: { for: "select-month" } }, [
                    _vm._v("Date Range:")
                  ]),
                  _c("DateRangeSelector", {
                    ref: "datePicker",
                    attrs: {
                      horizontal: "",
                      select_picker: "",
                      default_filter_mode: _vm.default_filter_mode,
                      default_month: _vm.default_month,
                      default_start_date: _vm.default_start_date,
                      default_end_date: _vm.default_end_date,
                      default_calendar_year: _vm.default_calendar_year,
                      default_financial_year: _vm.default_financial_year
                    },
                    on: {
                      input: _vm.dateSelected,
                      date_mode_change: _vm.dateModeChange
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "flex-row mt-2" }, [
                _c(
                  "div",
                  { staticClass: "flex-column" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "green-label",
                        attrs: { for: "select-month" }
                      },
                      [_vm._v(" Trip Purpose: ")]
                    ),
                    _c("b-form-select", {
                      attrs: {
                        id: "select-month",
                        options: _vm.tripPurposeFilterOptions
                      },
                      model: {
                        value: _vm.tripPurposeFilter,
                        callback: function($$v) {
                          _vm.tripPurposeFilter = $$v
                        },
                        expression: "tripPurposeFilter"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex-column ml-5" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "green-label",
                        attrs: { for: "select-month" }
                      },
                      [_vm._v(" Trip State: ")]
                    ),
                    _c("b-form-select", {
                      attrs: {
                        id: "select-month",
                        options: _vm.tripStateFilterOptions
                      },
                      model: {
                        value: _vm.tripStateFilter,
                        callback: function($$v) {
                          _vm.tripStateFilter = $$v
                        },
                        expression: "tripStateFilter"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "flex-row mt-2" }, [
                _c(
                  "div",
                  { staticClass: "flex-column" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "green-label",
                        attrs: { for: "select-month" }
                      },
                      [_vm._v(" Correct Odometer: ")]
                    ),
                    _c(
                      "b-checkbox",
                      {
                        attrs: {
                          id: "select-month",
                          checked: _vm.correctOdometer === true
                        },
                        on: { change: _vm.toggleOdometerCorrect }
                      },
                      [_vm._v("Specify Odometer Reading")]
                    )
                  ],
                  1
                ),
                _vm.correctOdometer
                  ? _c("div", [
                      _c(
                        "label",
                        {
                          staticClass: "green-label",
                          attrs: { for: "select-month" }
                        },
                        [_vm._v(" Final Odometer Reading: ")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.finalOdometerReading,
                            expression: "finalOdometerReading"
                          }
                        ],
                        attrs: {
                          required: "",
                          min: "0",
                          type: "number",
                          id: "select-month"
                        },
                        domProps: { value: _vm.finalOdometerReading },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.finalOdometerReading = $event.target.value
                          }
                        }
                      })
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._m(0)
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c("button", { attrs: { type: "submit" } }, [
              _vm._v("Export to CSV")
            ]),
            _vm.modal_id
              ? _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$bvModal.hide(_vm.modal_id)
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              : _vm._e()
          ])
        ]
      ),
      this.taskId
        ? _c("TaskProgress", {
            attrs: { task_id: _vm.taskId },
            on: { finish: _vm.taskComplete }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint-box" }, [
      _c("h5", [_vm._v("Logbook Exports")]),
      _c("p", [
        _vm._v(
          "Logbook exports are used to generate a CSV file containing all of the trips a device has taken during a selected period. The extract contains times, distances, durations and odometer readings and is designed to be used for vehicle KM reports required for tax purposes."
        )
      ]),
      _c("p", [
        _vm._v(
          " If the final odometer reading in the report is incorrect (for example if you haven't updated your odometer in the system before, you can specify what the final odometer reading for the report should be. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }