var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logbook-container content" },
    [
      _c("div", { staticClass: "logbook-header" }, [
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.back,
            attrs: { title: "Back" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ]),
        _vm._m(0),
        _c("div")
      ]),
      _c("div", { staticClass: "logbook-controls-container" }, [
        _c("div", { staticClass: "logbook-control-inner" }, [
          _c(
            "div",
            { staticClass: "logbook-control" },
            [
              _c("label", [_vm._v("Devices:")]),
              _c("DeviceSelector", {
                attrs: {
                  emit_on_load: "",
                  dropdown_mode: "",
                  selection_mode: "multi"
                },
                on: { input: _vm.deviceChanged },
                model: {
                  value: _vm.selectedDevices,
                  callback: function($$v) {
                    _vm.selectedDevices = $$v
                  },
                  expression: "selectedDevices"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "logbook-control" },
            [
              _c("label", [_vm._v("Date Range:")]),
              _c("DateRangeSelector", {
                attrs: { horizontal: "", select_picker: "" },
                on: {
                  input: _vm.dateSelected,
                  date_mode_change: _vm.dateModeChange,
                  select_month: _vm.monthSelectChanged,
                  select_custom_start: _vm.selectCustomStart,
                  select_custom_end: _vm.selectCustomEnd,
                  select_calendar_year: _vm.selectCalYear,
                  select_financial_year: _vm.selectFiYear
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "logbook-control" },
            [
              _c("label", [_vm._v("Trip Types:")]),
              _c("b-select", {
                attrs: { options: _vm.tripStateFilters },
                on: { change: _vm.refreshTrips },
                model: {
                  value: _vm.tripStateFilter,
                  callback: function($$v) {
                    _vm.tripStateFilter = $$v
                  },
                  expression: "tripStateFilter"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "logbook-control-export slim-controls" }, [
            _c(
              "button",
              {
                on: {
                  click: function($event) {
                    return _vm.$bvModal.show("logbook-export-modal")
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa",
                  class: _vm.$config.icons.general.export
                }),
                _vm._v(" Export Logbook")
              ]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "trip-container-outer" },
        [
          _c("LoadingBox", { attrs: { loading: _vm.loading } }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.trips,
                  expression: "trips"
                }
              ],
              staticClass: "trips-table-container"
            },
            [
              _c("TripsTable", {
                attrs: {
                  trips: _vm.trips,
                  total_rows: _vm.totalRows,
                  show_device_names: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "pagination-container" }, [
        _c("div", { staticClass: "total-trip-indicator" }, [
          _vm._v(" Trips: " + _vm._s(_vm.totalRows) + " ")
        ]),
        _c(
          "div",
          { staticClass: "page-controls" },
          [
            _c("b-form-select", {
              staticClass: "page-select",
              attrs: {
                id: "perPageSelect",
                size: "sm",
                options: _vm.pageOptions
              },
              on: { change: _vm.onPageSizeChange },
              model: {
                value: _vm.perPage,
                callback: function($$v) {
                  _vm.perPage = $$v
                },
                expression: "perPage"
              }
            }),
            _c("b-pagination", {
              staticClass: "my-0 flex-grow-1",
              attrs: {
                "total-rows": _vm.totalRows,
                "per-page": _vm.perPage,
                align: "fill",
                size: "sm"
              },
              on: { change: _vm.onPageChange },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "logbook-export-modal",
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Export Logbook"
          }
        },
        [
          _c("LogbookExporter", {
            attrs: {
              modal_id: "logbook-export-modal",
              default_filter_mode: _vm.dateFilterMode,
              default_month: _vm.defaultMonth,
              default_start_date: _vm.defaultStartDate,
              default_end_date: _vm.defaultEndDate,
              default_financial_year: _vm.defaultFinancialYear,
              default_calendar_year: _vm.defaultCalendarYear
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h1", [_vm._v("Vehicle Trips")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }