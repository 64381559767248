<template>
  <div class="logbook-container content">
    <div class="logbook-header">
      <div>
        <i class="icon-button"
        :class="$config.icons.general.back"
        v-b-tooltip
        title="Back"
        @click="$router.back()"></i>
      </div>
      <div>
        <h1>Vehicle Trips</h1>
      </div>
      <div></div>
    </div>
    <div class="logbook-controls-container">
      <div class="logbook-control-inner">
        <div class="logbook-control">
          <label>Devices:</label>
            <DeviceSelector v-model="selectedDevices" emit_on_load dropdown_mode @input="deviceChanged"
                            selection_mode="multi"></DeviceSelector>
        </div>
        <div class="logbook-control">
          <label>Date Range:</label>
           <DateRangeSelector horizontal select_picker
                              @input="dateSelected"
                              @date_mode_change="dateModeChange"
                              @select_month="monthSelectChanged"
                              @select_custom_start="selectCustomStart"
                              @select_custom_end="selectCustomEnd"
                              @select_calendar_year="selectCalYear"
                              @select_financial_year="selectFiYear"
           ></DateRangeSelector>
        </div>
        <div class="logbook-control">
          <label>Trip Types:</label>
            <b-select :options="tripStateFilters" v-model="tripStateFilter" @change="refreshTrips"></b-select>
        </div>
        <div class="logbook-control-export slim-controls" >
<!--          <i class="fa" :class="$config.icons.general.export"></i>-->
          <button @click="$bvModal.show('logbook-export-modal')"><i class="fa" :class="$config.icons.general.export"></i> Export Logbook</button>
        </div>
      </div>
      </div>
    <div class="trip-container-outer">
      <LoadingBox :loading="loading"></LoadingBox>
      <div v-show="trips" class="trips-table-container">
        <TripsTable :trips="trips" :total_rows="totalRows" show_device_names></TripsTable>
      </div>
    </div>
    <div class="pagination-container">
      <div class="total-trip-indicator">
        Trips: {{totalRows}}
      </div>
      <div class="page-controls">
        <b-form-select
          v-model="perPage"
          @change="onPageSizeChange"
          id="perPageSelect"
          size="sm"
          :options="pageOptions"
          class="page-select"
        >
        </b-form-select>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="onPageChange"
          align="fill"
          size="sm"
          class="my-0 flex-grow-1"
        ></b-pagination>
      </div>
    </div>
    <b-modal id="logbook-export-modal" centered class="modal-content" size="lg" hide-footer title="Export Logbook">
      <LogbookExporter modal_id="logbook-export-modal"
                       :default_filter_mode="dateFilterMode"
                       :default_month="defaultMonth"
                       :default_start_date="defaultStartDate"
                       :default_end_date="defaultEndDate"
                       :default_financial_year="defaultFinancialYear"
                       :default_calendar_year="defaultCalendarYear"
      ></LogbookExporter>
    </b-modal>

  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as config from '@/config'
import moment from 'moment/moment'
import DateRangeSelector from '@/components/shared/DateRangeSelector.vue'
import LoadingBox from '@/components/helpers/LoadingBox.vue'
import LogbookExporter from '@/components/device/Logbook/LogbookExporter.vue'
import TripsTable from '@/components/trips/TripsTable.vue'
import DeviceSelector from '@/components/shared/DeviceSelector.vue'

export default {
  name: 'logbook',
  components: {DeviceSelector, TripsTable, LogbookExporter, LoadingBox, DateRangeSelector},
  data: function () {
    return {
      loading: true,
      devices: null,
      selectedDevices: 'all',
      trips: [],
      isAdmin: null,
      filterMode: 'Today',
      filterOptions: [
        'Today',
        'Yesterday',
        'This Week',
        'Custom'
      ],
      tripStateFilters: [
        {
          value: null,
          text: '< Any >'
        },
        {
          value: 200,
          text: 'Moving Only'
        },
        {
          value: 300,
          text: 'Parked Only'
        }
      ],
      tripStateFilter: null,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
      editedRows: [],
      initialValues: {},
      customDate: null,
      trip_ids: [],
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      onFiltered: [],
      totalRows: 0,
      sortDirection: 'asc',
      startDate: moment().startOf('day').toISOString(),
      endDate: moment().endOf('day').toISOString(),
      // Values to synchronise with the log book exporter
      dateFilterMode: null,
      defaultMonth: null,
      defaultStartDate: null,
      defaultEndDate: null,
      dateRange: null,
      defaultFinancialYear: null,
      defaultCalendarYear: null
    }
  },
  async mounted() {
    this.isAdmin = await this.$auth.roleIn(config.roles.admin)
    // await this.updateDevices()
    // await this.refreshTrips()
  },
  computed: {
  },
  methods: {
    dateModeChange (newMode) {
      this.dateFilterMode = newMode
    },
    monthSelectChanged (newMonth) {
      this.defaultMonth = newMonth
    },
    selectCustomStart (newStart) {
      this.defaultStartDate = newStart
    },
    selectCustomEnd (newEnd) {
      this.defaultEndDate = newEnd
    },
    selectCalYear (newYear) {
      this.defaultCalendarYear = newYear
    },
    selectFiYear (newYear) {
      this.defaultFinancialYear = newYear
    },
    dateSelected (dates) {
      console.log('Date selected: ', dates)
      this.startDate = dates.start
      this.endDate = dates.end
      if (this.selectedDevices !== 'all') {
        this.refreshTrips()
      }
    },
    async refreshTrips () {
      this.loading = true
      let resp = await DataProvider.getTrips(
        this.selectedDevices, this.startDate, this.endDate, this.tripStateFilter, false, this.perPage,
        (this.currentPage - 1) * this.perPage, false, true)
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
        this.trips = []
        this.totalRows = 0
        this.loading = false
        return []
      } else {
        this.totalRows = resp.data.total_trips
        this.trips = resp.data.trips
        this.loading = false
      }
    },
    deviceChanged() {
      if (this.startDate && this.endDate) {
        this.refreshTrips()
      }
    },
    /***
     * Pagination Controls select a new page.
     * @param newPage
     */
    onPageChange (newPage) {
      this.currentPage = newPage
      this.refreshTrips()
    },
    /****
     * Pagination controls change page size
     * @param newPageSize
     */
    onPageSizeChange (newPageSize) {
      this.perPage = newPageSize
      this.refreshTrips()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';



.logbook-container {
  display: flex;
  flex-direction: column;
  background-color: $theme-color-background-1;
  overflow: hidden;
  width: 80%;
  min-width: 600px;
}

.logbook-header {
  display: flex;
  flex-direction: row;
  font-size: 2em;
  justify-content: space-between;
  align-items: center;
  color: $text-color-heading;
  font-weight: 600;
  padding: 5px;
  background-color: $theme-color-background-2;
}

.logbook-controls-container {
  z-index: 5;
  display: flex;
  flex-direction: row;
  background-color: $theme-color-background-1;
  color: white;
  border-bottom: 1px solid darkgray;
  justify-content: stretch;
}

.logbook-control-inner {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 5px;
  justify-content: flex-start;
  border: 1px solid $theme-color-primary-3;
  border-radius: 10px;
  flex-grow: 1;

}

.logbook-control-inner> div:not(:first-child) {
  margin-left: 2em
}

.logbook-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logbook-control-export{
  display: flex;
  align-content: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-grow: 1;
}

.trip-container-outer {
  position: relative;
  min-height: 300px;
  background: $theme-color-background-2;
  overflow: hidden;
}

.trips-table-container{
  //width: 99%;
  //min-width: 600px;
  padding: 5px;
  overflow: auto;
  max-height: 100%;
}

.pagination-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  padding: 5px 15px;
  justify-content: space-between;
  border-top: 1px solid darkgray;
}

.page-controls {
  display: flex;
  flex-flow: row nowrap;
}

.page-select {
  width: 10%;
  min-width: 75px;
}

.total-trip-indicator {
  color: white;
  font-weight: 500;
}

</style>
