<template>
  <div class="trips-table">
      <div v-show="trips && deviceList" class="trips-list-container">
        <b-table
          striped hover :items="trips" :fields="fields" :selectable="selectable"
                 responsive="sm" class="trips-list-table"
                 tbody-tr-class="table-body-centered"
                 ref="tripsTable"
        >
          <template v-slot:cell(view_trip)="row">
            <b-button @click="showTrip(row.item)" variant="success"
              v-b-tooltip title="Open Trip in New Tab">
              <i class="fa fa-map-marker save-icons"></i>
            </b-button>
          </template>

          <template v-slot:cell(device_imei)="row">
            <div class="flex-column align-items-center">
              <DeviceAvatar v-if="deviceList" :device="getDevice(row.item.device_imei)"></DeviceAvatar>
            </div>
          </template>

          <template v-slot:cell(start_time)="row">
            <div class="flex-column align-items-center">
              <div>{{dt.timestampToLocalDateTime(row.item.start_time)}}</div>
              <div class="address-text">{{getAddress(row.item, 'start')}}</div>
            </div>
          </template>

          <template v-slot:cell(end_time)="row">
            <div class="flex-column align-items-center">
              <div>{{dt.timestampToLocalDateTime(row.item.end_time)}}</div>
              <div>{{getAddress(row.item, 'end')}}</div>
            </div>
          </template>

          <template v-slot:cell(state)="row">
            <div class="flex-column align-items-center" v-if="isVehicleTrip(row.item)">
              <img class="trip-icon" :src="getTripIcon(row.item)" v-if="isVehicleTrip(row.item)">
              {{row.item.state}}
            </div>
            <div class="flex-column align-items-center" v-else>
              <i class="trip-icon trip-icon-border" :class="$config.icons.general.locations"></i>
              LOCATION
            </div>
          </template>
          <template v-slot:cell(modified)="row">
            <div class="slim-controls" v-if="row.item.modified">
              <button class="" @click="showTripAuditLog(row.item)">Yes</button>
            </div>
            <span v-else>No</span>
          </template>
          <template v-slot:cell(work_use)="row">
            <b-checkbox v-model="row.item.work_use"
                        @change="rowEdited(row.item.id, row.item.id)"></b-checkbox>
          </template>

          <template v-slot:cell(tags)="row">
            <div class="tags-container">
              <TripTags v-model="row.item.tags" editable @input="rowEdited(row.item.id)"></TripTags>
            </div>
          </template>

          <template v-slot:cell(forms)="row">
            <div class="tags-container">
              <FormListInline v-model="row.item" editable @input="tripFormsUpdated"></FormListInline>
            </div>
          </template>

          <template v-slot:cell(annotation)="row">
            <div class="align-content-center">
              <b-form-input class="custom-control custom-checkbox"
                            v-model="row.item.annotation"
                            debounce="1000"
                            @input="rowEdited(row.item.id)"></b-form-input>
            </div>
          </template>
          <template v-slot:cell(save)="row">
            <div class="fixed-width-save">
              <b-button @click="updateTrip(row.item)" variant="success" v-if="editedRows.includes(row.item.id)">
                <i class="fa fa-check save-icons"></i>
              </b-button>
              <b-button @click="resetRow(row.item.id)" variant="danger" v-if="editedRows.includes(row.item.id)">
                <i class="fa fa-times save-icons"></i>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <div v-if="(trips === null || trips.length === 0)" class="trips-list-container">
        <div class="no-trips-container">
          <h3 class="no-trips-text">No trips found for this period.</h3>
        </div>
      </div>
    <b-modal id="modal-trip-auditlog" centered class="modal-content" size="lg" title="Trip Auditlog"
             ok-only ok-title="Close">
      <AuditLog v-if="auditLogTrip" related_type="trip" :related_id="auditLogTrip.id"></AuditLog>
    </b-modal>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as config from '@/config'
import moment from 'moment/moment'
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'
import {MetricFormatter as mf} from '@/components/helpers/MetricFormatter'
import DeviceAvatar from '@/components/device/DeviceAvatar.vue'
import * as IconHelper from '@/components/helpers/IconHelper'
import TripTags from '@/components/trips/TripTags.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'
import FormListInline from '@/components/forms/FromListInline.vue'

export default {
  name: 'trips-table',
  components: {FormListInline, AuditLog, TripTags, DeviceAvatar},
  props: {
    trips: Array,
    editable: Boolean,
    selectable: Boolean,
    show_device_names: Boolean
  },
  emits: [
    'input', // Trips Selected
  ],
  data: function () {
    return {
      dt: dt,
      selectedDevice: null,
      isAdmin: null,
      // Filters
      editedRows: [],
      initialValues: {},
      trip_ids: [],
      filter: null,
      filterOn: [],
      onFiltered: [],
      sortDirection: 'asc',
      deviceList: null,
      auditLogTrip: null
    }
  },
  async mounted() {
    this.isAdmin = await this.$auth.roleIn(config.roles.admin)
    await this.getDevices()
  },
  filters: {
    title(str) {
      str = str.toLowerCase().split(' ')
      let final = [ ]
      for (let word of str) {
        final.push(word.charAt(0).toUpperCase() + word.slice(1))
      }

      return final.join(' ')
    },
    monthName: function(month) {
      return moment(month, 'YYYY-MM').format('MMM YY')
    }
  },
  methods: {
    async getDevices() {
      let deviceCall = await DataProvider.getDeviceList(
        ['device_name', 'device_imei', 'device_code', 'icon', 'device_type', 'features'])
      this.deviceList = deviceCall.data
      console.log(this.deviceList)
    },
    rowEdited(rowId) {
      console.log('rowEdited', rowId)
      this.editedRows.push(rowId)
    },
    tripFormsUpdated(data) {
      console.log('FORM CHANGE', data)
      console.log(this.trips)
      if (data) {
        let i = this.trips.findIndex(trip => trip.id === data.id)
        // We're not replacing the whole trip as the table has trouble dealing with this (Vue change detections)
        this.trips[i].trip_forms = data.trip_forms
      }
    },
    resetRow(rowId) {
      // From the list trips, reset the row where the id == rowId
      console.log('resetRow', rowId)
      if (this.trips) {
        let i = this.trips.findIndex(trip => trip.id === rowId)
        this.trips[i] = {...this.initialValues[rowId]}
        this.editedRows = this.editedRows.filter(i => i !== rowId)
        this.$refs.tripsTable.refresh()
      }
    },
    showTrip(trip) {
      let routeData = this.$router.resolve({
        path: `/device/${trip.device_imei}`,
        query: {
          start_date: moment.unix(trip.start_time).startOf('day').toISOString(),
          end_date: moment.unix(trip.end_time).endOf('day').toISOString(),
          trip_id: trip.id
        }
      })
      window.open(routeData.href, '_blank')
    },
    cacheUneditedTrips(newData) {
      this.initialValues = {}
      this.editedRows = []
      newData.forEach((trip) => {
        this.initialValues[trip.id] = {...trip}
      })
    },
    async updateTrip(item) {
      console.log('Updating item')
      console.log(item)

      let result = await DataProvider.updateDeviceTrips(item.device_imei, [item])
      if (result.success) {
        item.modified = true
        this.editedRows = this.editedRows.filter(i => i !== item.id)
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    },
    getDevice(deviceImei) {
      if (this.deviceList && Object.hasOwn(this.deviceList, deviceImei)) {
        return this.deviceList[deviceImei]
      } else {
        return null
      }
    },
    getTripIcon(trip) {
      if (Object.hasOwn(IconHelper.tripIcons, trip.state)) {
        return IconHelper.tripIcons[trip.state]
      } else {
        return ''
      }
    },
    isVehicleTrip(trip) {
      // Lookup the Device in the List, see if it has the 'device_trips' feature.
      if (this.deviceList && Object.hasOwn(this.deviceList, trip.device_imei)) {
        return this.$deviceService.hasFeatureSync(this.deviceList[trip.device_imei].device_type, 'device_trips')
      } else {
        return true
      }
    },
    showTripAuditLog(trip) {
      this.auditLogTrip = trip
      this.$bvModal.show('modal-trip-auditlog')
    },
    trimAddress(addressText) {
      if (addressText.includes('Australia')) {
        return addressText.substring(0, addressText.length - 16)
      }
    },
    getAddress(trip, position = 'start') {
      if (position === 'start' && trip.start_location_address) {
        return this.trimAddress(trip.start_location_address.data.address_text)
      } else if (position === 'end' && trip.end_location_address) {
        return this.trimAddress(trip.end_location_address.data.address_text)
      } else {
        return ''
      }
    }
  },
  computed: {
    fields() {
      let fields = [
        {key: 'view_trip', label: 'View', sortable: true, skip_csv: true},
        {key: 'start_time', label: 'Start', sortable: true, formatter: dt.timestampToLocalDateTime},
        // {key: 'start_position', label: 'Start Position', sortable: true},
        {key: 'end_time', label: 'End', sortable: true, formatter: dt.timestampToLocalDateTime},
        // {key: 'end_position', label: 'End Position', sortable: true},
        {key: 'state', label: 'State', sortable: true},
        {key: 'time_taken', label: 'Duration', sortable: true, formatter: (x) => dt.secondsToTime(x, 'HH:mm')},
        {key: 'kms_travelled', label: 'Distance', sortable: true, formatter: mf.kmsFormat},
        // {key: 'fuel_consumed', label: 'Fuel', sortable: true},
        // {key: 'ignition_on', label: 'ACC ON?', sortable: true},
        {key: 'modified', label: 'Edited', sortable: true},
        {key: 'work_use', label: 'Work?', sortable: true},
        {key: 'tags', label: 'Tags', sortable: true},
        {key: 'forms', label: 'Forms', sortable: true},
        {key: 'annotation', label: 'Annotation', sortable: true},
        {key: 'save', label: '', skip_csv: true},
      ]
      if (this.show_device_names) {
        fields.splice(1, 0, {key: 'device_imei', label: 'Device', sortable: true, skip_csv: true})
      }
      return fields
    }
  },
  watch: {
    trips (newTrips) {
      this.cacheUneditedTrips(newTrips)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.is-unsaved {
  background-color: $theme-color-primary-3;
}

.fixed-width-save {
  display: flex;
  flex-flow: row nowrap;
  width: 3em;
  justify-content: center;
}

.trips-list-container {
  //width: 99%;
  //min-width: 600px;
  padding: 5px;
  overflow-y: auto;
  max-height: 100%;
}

.align-content-center {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.align-content-center input {
  margin-bottom: 0;
}

.save-icons {
  font-size: 1em!important;
}

.no-trips-text {
  color: $text-color-invert
}

.trip-icon {
  width: 38px;
  color: white;
  font-size: 36px;
  text-align: center;
}

// Add a border to match the images
.trip-icon-border {
  border: 1px solid #DDDDDD;
  border-radius: 3px;
}

.tags-container {
  position: static;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 20vw;
  z-index: 10;
}

.address-text {
  white-space: pre-wrap;
}

</style>
